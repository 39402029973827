export const PRICING_PLANS= {
    "UNKNOWN": {
        "CURRENCY": "USD ",
        "DIGITAL": {
            "MONTHLY": "24.90",
            "ANNUAL": "20.75",
            "SAVING": "49.80"
        },
        "PLUS": {
            "MONTHLY": "34.90",
            "ANNUAL": "28.99",
            "SAVING": "69.80"
        }
    },
    "UY": {
        "CURRENCY": "$UY ",
        "DIGITAL": {
            "MONTHLY": "990",
            "ANNUAL": "825",
            "SAVING": "1.980"
        },
        "PLUS": {
            "MONTHLY": "1.290",
            "ANNUAL": "1.075",
            "SAVING": "2.580"
        }
    },
    "AR": {
        "CURRENCY": "$AR ",
        "DIGITAL": {
            "MONTHLY": "13.590",
            "ANNUAL": "11.325",
            "SAVING": "27.180"
        },
        "PLUS": {
            "MONTHLY": "18.900",
            "ANNUAL": "15.750",
            "SAVING": "37.800"
        }
    },
    "CL": {
        "CURRENCY": "$CL ",
        "DIGITAL": {
            "MONTHLY": "21.900",
            "ANNUAL": "18.250",
            "SAVING": "43.800"
        },
        "PLUS": {
            "MONTHLY": "29.900",
            "ANNUAL": "24.917",
            "SAVING": "59.800"
        }
    },
    "PE": {
        "CURRENCY": "$PE ",
        "DIGITAL": {
            "MONTHLY": "49",
            "ANNUAL": "41",
            "SAVING": "890"
        },
        "PLUS": {
            "MONTHLY": "89",
            "ANNUAL": "74",
            "SAVING": "178"
        }
    },
    "CO": {
        "CURRENCY": "$CO ",
        "DIGITAL": {
            "MONTHLY": "69.000",
            "ANNUAL": "57.500",
            "SAVING": "138.000"
        },
        "PLUS": {
            "MONTHLY": "119.000",
            "ANNUAL": "99.000",
            "SAVING": "238.000"
        }
    },
    "MX": {
        "CURRENCY": "$MX ",
        "DIGITAL": {
            "MONTHLY": "449",
            "ANNUAL": "374",
            "SAVING": "898"
        },
        "PLUS": {
            "MONTHLY": "649",
            "ANNUAL": "541",
            "SAVING": "1.298"
        }
    }
};
