import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import FooterComponent from "../components/footerComponent";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
import PlansCompoennt from "../components/plansComponent";
import utils from "../utils/utils";
import FaqComponent from "../components/faqComponent";

const PricingView = (props) => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isAnually, setIsAnually] = useState(true)

  useEffect(() => {
    utils.trackingMetric("PRICING_VIEW")
    scrollToTop()
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const scrollTo = (id, offset) => {
    scroller.scrollTo(id, {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: offset
    });
  }


  return (
    <React.Fragment>
      < section id="home" name="home-nav" style={{ backgroundColor: "#fff6f6", padding: "100px 0px", marginTop: "10px" }}>
        {/* container start */}
        < div className="container" >
          {/* vertical animation line */}
          {/* row start */}
          <div className="row">
            <div className="col-lg-12 col-md-12" data-aos="fade-right" data-aos-duration={1500}>
              {/* banner text */}
              <div className="banner_text">
                {/* h1 */}
                <p style={{ textAlign: "center" }}>
                  <span><Link to="/">{t("_HOME")}</Link> » {t("pricing.plans")}</span>
                </p>
                <h1 style={{ marginTop: "30px", textAlign: "center" }}>
                  {t("pricing.digitalPlan")} <br /> {t("pricing.need")}
                </h1>
                <p style={{ textAlign: "center" }}>{t("pricing.subTitle")}</p>
                <p style={{ textAlign: "center" }}>
                  <a className="button-cover" style={{ marginRight: "15px" }} href="https://miembros.logpot.io/creacion-de-organizacion-datos-iniciales" target="_blank" rel="noopener noreferrer">{t("navbar.button")}</a>
                </p>
              </div>
              {/* users */}
            </div>
          </div>
          {/* row end */}
        </div >
        {/* container end */}
      </section >
      {/* <PlansCompoennt /> */}
      <FaqComponent />
      <FooterComponent />
    </React.Fragment>
  );
};

export default PricingView;
