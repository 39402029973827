import React, { useEffect } from "react";
import "./homeView.scss";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import LazyLoad from 'react-lazyload';
import FooterComponent from "../../components/footerComponent";
import PlansCompoennt from "../../components/plansComponent";
import LoadingComponent from "../../components/loadingComponent"
import { useState } from "react";
import utils from "../../utils/utils";
import FaqComponent from "../../components/faqComponent";
const HomeView = (props) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    utils.trackingMetric("HOME_VIEW")
    scrollToTop()
    setTimeout(() => {
      setLoading(false)
    }, 300);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <React.Fragment>
      {loading === true && <LoadingComponent/> }
      {/* Page-wrapper-Start */}
      < div className="page_wrapper" id="home">
        {/* Preloader */}
        {/* Header Start */}
        {/* Banner-Section-Start */}

        < section className="banner_section" id="home" name="home-nav">
          {/* container start */}
          < div className="container" >
            {/* vertical animation line */}
            {/* row start */}
            < div className="row" >
              <div className="col-lg-6 col-md-12" data-aos="fade-right" data-aos-duration={1500}>
                {/* banner text */}
                <div className="banner_text">
                  {/* h1 */}
                  <h1>
                    <Trans i18nKey="cover.title" components={{ span: <span /> }}
                    /></h1>
                  {/* p */}
                  <p dangerouslySetInnerHTML={{ __html: t('cover.description') }} />

                  <a className="button-cover button-buy" style={{ marginRight: "15px" }} href="https://miembros.logpot.io/creacion-de-organizacion-datos-iniciales" target="_blank" rel="noopener noreferrer">{t("navbar.button")}</a>
                  <div className="trial_box_list">
                    <ul>
                      <li><i className="icofont-check-circled" /> {t("navbar.booking")}</li>
                      <li><i className="icofont-check-circled" /> {t("navbar.menu")}</li>
                      <li><i className="icofont-check-circled" /> {t("navbar.pages")}</li>
                    </ul>
                  </div>
                </div>
                {/* users */}
              </div>
              {/* banner slides start */}
              <div className="col-lg-6 col-md-12" data-aos="fade-in" data-aos-duration={5000} >
                <div className="banner_slider">
                  <OwlCarousel className='owl-carousel owl-theme' loop={true} autoplay={true} autoplayTimeout={5000} margin={10} items={1} id="frmae_slider">
                    <div className="item">
                      <div className="slider_img">
                          <img src={require("../../assets/home/image1.svg")} alt="pages" style={{height: "100%"}}/>
                      </div>
                    </div>
                    <div className="item">
                      <div className="slider_img">
                          <img src={require("../../assets/home/image2.svg")} alt="booking" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="slider_img">
                          <img src={require("../../assets/home/image3.svg")} alt="menu" />
                      </div>
                    </div>
                  </OwlCarousel>
                  <div className="slider_frame">
                      <img src={require("../../assets/mobile_frame_svg.svg")} alt="iphone template" />
                  </div>
                </div>
              </div>
              {/* banner slides end */}
            </div >
            {/* row end */}
          </div >
          {/* container end */}
        </section >
        {/* Banner-Section-end */}
        {/* Trusted Section start */}
        <section className="row_am download_section">
          {/* container start */}
          <div className="container" style={{ textAlign: "center" }}>
            {/* icon start */}
            <h2><Trans i18nKey="app.title" components={{ span: <span /> }} /></h2>
            <p><Trans i18nKey="app.description" components={{ br: <br /> }} /></p>
            <ul className="app_btn aos-init aos-animate" data-aos="fade-in" data-aos-duration={1500}>
              <li>
                <a href="https://apps.apple.com/uy/app/logpot-admin/id1664265745" className="app_store" target="_blank">
                  <img className="blue_img" src="images/appstore_blue.png" alt="appstore" />
                  <img className="white_img" src="images/appstore_white.png" alt="appstore" />
                </a>
              </li>
              <li>
                <a href="https://play.google.com/store/apps/details?id=io.logpot.clients.backoffice&pli=1" target="_blank">
                  <img className="blue_img" src="images/googleplay_blue.png" alt="android" />
                  <img className="white_img" src="images/googleplay_white.png" alt="android" />
                </a>
              </li>
            </ul>
            {/* icon  end */}
          </div>
          {/* container end */}
        </section>
        {/* Trusted Section ends */}
        {/* Features-Section-Start */}
        {/* Features-Section-end */}
        {/* About-App-Section-Start */}
        <section className="row_am features_section" id="features">
          {/* container start */}
          <div className="container">
            <div className="features_inner">
              <div className="section_title aos-init aos-animate" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={300}>
                {/* h2 */}
                <h2 dangerouslySetInnerHTML={{ __html: t('clients.title') }} />
                {/* p */}
              </div>
              {/* story */}
              <div className="features_block">
                <div className="row">
                  <div className="col-md-4">
                    <div className="feature_box aos-init aos-animate" data-aos="fade-up" data-aos-duration={1500}>
                      <div className="image">
                        <img src={require("../../assets/icons/hub.svg")} alt="hub" className="features-icon" />
                      </div>
                      <div className="text">
                        <h4>{t("clients.option1_title")}</h4>
                        <p>
                          {t("clients.option1_description")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="feature_box aos-init aos-animate" data-aos="fade-up" data-aos-duration={1700}>
                      <div className="image">
                        <img src={require("../../assets/icons/help.svg")} alt="help" className="features-icon" />
                      </div>
                      <div className="text">
                        <h4>{t("clients.option2_title")}</h4>
                        <p>
                          {t("clients.option2_description")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="feature_box aos-init aos-animate" data-aos="fade-up" data-aos-duration={1900}>
                      <div className="image">
                        <img src={require("../../assets/icons/translate.svg")} alt="translate" className="features-icon" style={{ padding: "12px" }} />
                      </div>
                      <div className="text">
                        <h4>{t("clients.option3_title")}</h4>
                        <p>
                          {t("clients.option3_description")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* container end */}
        </section>
        <section className="trusted_section" name="home-clients">
          {/* container start */}
          <div className="container">
            {/* logos slider start */}
            <div className="company_logos">
              <LazyLoad>
              <OwlCarousel className='owl-theme owl-carousel' loop={true} autoplay={true} autoplayTimeout={3000} margin={10} items={5} id="frmae_slider" >
                <div className="item">
                  <div className="logo">
                    <img src={require("../../assets/clients/pv.png")} alt="pv" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo">
                    <img src={require("../../assets/clients/fellini.png")} alt="fellini" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo">
                    <img src={require("../../assets/clients/hdj.png")} alt="hdj" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo">
                    <img src={require("../../assets/clients/marcos.png")} alt="marcos" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo">
                    <img src={require("../../assets/clients/renatabarhouse.png")} alt="renata" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo">
                    <img src={require("../../assets/clients/harlem.png")} alt="harlem" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo">
                    <img src={require("../../assets/clients/teacordasbar.png")} alt="te acordas" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo">
                    <img src={require("../../assets/clients/box.png")} alt="box" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo">
                    <img src={require("../../assets/clients/jet.png")} alt="jet" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo">
                    <img src={require("../../assets/clients/rostand.png")} alt="rostand" />
                  </div>
                </div>
              </OwlCarousel>
              </LazyLoad>
            </div>
            {/* logos slider end */}
          </div>
          {/* container end */}
        </section>
        {/* How-It-Workes-Section-end */}
        {/* Testimonial-Section start */}
        <section className="row_am modern_ui_section" style={{ marginTop: "40px" }}>
          {/* section bg */}
          {/* container start */}
          <div className="container">
            {/* row start */}
            <div className="row">
              <div className="col-lg-6">
                {/* UI content */}
                <div className="ui_text">
                  <div className="section_title aos-init aos-animate" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={100}>
                    <h2 dangerouslySetInnerHTML={{ __html: t('optimization.title') }} />
                    <p>{t("optimization.description")}</p>
                  </div>
                  <ul className="design_block">
                    <li data-aos="fade-up" data-aos-duration={1500} className="aos-init aos-animate">
                      <h4>{t("optimization.option1_title")}</h4>
                      <p style={{ marginBottom: "0px" }}>{t("optimization.option1_description")}</p>
                      <Link to="/booking" style={{
                        marginRight: "15px", fontWeight: "700",
                        textDecorationLine: "underline"
                      }} >{t("optimization.button_info")}</Link>
                    </li>
                    <li data-aos="fade-up" data-aos-duration={1500} className="aos-init aos-animate">
                      <h4>{t("optimization.option2_title")}</h4>
                      <p style={{ marginBottom: "0px" }}>{t("optimization.option2_description")}</p>
                      <Link to="/pages" style={{
                        marginRight: "15px", fontWeight: "700",
                        textDecorationLine: "underline"
                      }} >{t("optimization.button_info")}</Link>
                    </li>
                    <li data-aos="fade-up" data-aos-duration={1500} className="aos-init aos-animate">
                      <h4>{t("optimization.option3_title")}</h4>
                      <p style={{ marginBottom: "0px" }}>{t("optimization.option3_description")}</p>
                      <Link to="/menu" style={{
                        marginRight: "15px", fontWeight: "700",
                        textDecorationLine: "underline"
                      }} >{t("optimization.button_info")}</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                {/* UI Image */}
                <div className="ui_images aos-init aos-animate" data-aos="fade-in" data-aos-duration={1500}>
                  <div className="left_img">
                    <LazyLoad>
                    <img className="moving_position_animatin" src={require("../../assets/products/portal.png")} alt="portal" style={{ maxWidth: "650px" }} />
                    </LazyLoad>
                  </div>
                  {/* UI Image */}

                </div>
              </div>
            </div>
            {/* row end */}
          </div>
          {/* container end */}
        </section>
        <section className="row_am free_app_section" id="getstarted">
          {/* container start */}
          <div className="container-fluid">
            <div className="container">
              <div className="free_app_inner" data-aos="fade-in" data-aos-duration={1500} data-aos-delay={100}>
                {/* vertical line animation */}
                {/* row start */}
                <div className="row">
                  {/* content */}
                  <div className="col-md-6">
                    <div className="free_img">
                      <img src={require("../../assets/template/menu_template_02.svg")} alt="menu" style={{ height: "519px" }} />
                      <img className="mobile_mockup" src={require("../../assets/template/menu_template_01.svg")} alt="mobile template" style={{ height: "626px" }} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="free_text">
                      <div className="section_title">
                        <h2>{t("modal.title")}</h2>
                        <p>{t("modal.description")}</p>
                      </div>
                      <ul className="app_btn">
                        <li style={{ marginLeft: "0px" }}>
                          <a href="https://miembros.logpot.io/creacion-de-organizacion-datos-iniciales"  className="btn white_btn button-buy" target="_blank" rel="noopener noreferrer">{t("modal.button")}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* images */}

                </div>

                {/* row end */}
              </div>
            </div>
          </div>
          {/* container end */}
        </section>
       <FaqComponent/>
      </div >
      <FooterComponent />
    </React.Fragment>
  );
};

export default HomeView;
