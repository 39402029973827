import React, { Suspense } from "react";
import { render } from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import AppRoutes from "./utils/routes";
import Loading from "./components/loading/loading";
import LoadingComponent from "./components/loadingComponent";
import './i18n/i18n';
import AOS from "aos";
import mixpanel from 'mixpanel-browser';
import utils from "./utils/utils";
import { v4 as uuidv4 } from 'uuid';
import TagManager from 'react-gtm-module';

AOS.init();
mixpanel.init(process.env.REACT_APP_MIX_PANEL, {debug: false});
TagManager.initialize({ gtmId: 'GTM-WZS732X' });

let guestUser = utils.getCookie()

if(guestUser === null){
  guestUser = utils.getRandomVariant() + "." + uuidv4();
  utils.setUUIDCookie(guestUser)
} 
mixpanel.identify(guestUser)
utils.trackingMetric("SESSION_START")


render(
  <div className="app-style">
    <Suspense fallback={<LoadingComponent />}>
      <AppRoutes/>
    </Suspense>
  </div>
,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
