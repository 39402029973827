import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import App from "../App";
import { connect } from "react-redux";
import Home from "../views/homeView/homeView";
import TermsConditions from "../views/termsConditions";
import MenuView from "../views/menuView";
import NavbarComponent from "../components/navbarComponent";
import BookingView from "../views/bookingView";
import PagesView from "../views/pagesView";
import PricingView from "../views/pricingView";
//Components

const AppRoutes = () => (
  <App>
    <BrowserRouter>
      <Route
        render={(location) => (
          <React.Fragment>
            <NavbarComponent />
            <Switch>
              <Route path="/privacy-policies" component={TermsConditions} />
              <Route path="/menu" component={MenuView} />
              <Route path="/booking" component={BookingView} />
              <Route path="/pages" component={PagesView} />
              <Route path="/pricing" component={PricingView} />
              <Route path="/" component={Home} />
            </Switch>
          </React.Fragment>

        )}
      />
    </BrowserRouter>
  </App>
);

export default AppRoutes;
