import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { useTranslation, Trans } from "react-i18next";
import utils from "../utils/utils";

const FooterComponent = () => {
  const { t, i18n } = useTranslation();

    return( 
    <FooterContainer>
    <div className="top_footer" id="contact">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-6 col-12">
          <div className="abt_side">
            <div className="logo"> <img src={require("../assets/bussiness/logo-orange.svg")} alt="image" /></div>
            <ul>
              <li>hello@logpot.io</li>
            </ul>
            <ul className="social_media">
              <li><a href="https://www.instagram.com/logpot.io/" target="_blank"><i className="icofont-instagram" /></a></li>
              <li><a href="https://www.linkedin.com/company/logpot/" target="_blank"><i className="icofont-linkedin" /></a></li>
              <li><a href="https://www.facebook.com/LogPotDigital" target="_blank"><i className="icofont-facebook" /></a></li>
            </ul>
          </div>
        </div>
        {/* footer link 2 */}
        <div className="col-lg-3 col-md-6 col-12">
          <div className="links">
            <h3>{t("footer.links")}</h3>
            <ul>
              <li><Link to="/">{t("footer.link_home")}</Link></li>
              <li><Link to="/menu">{t("footer.link_menu")}</Link></li>
              <li><Link to="/bookings">{t("footer.link_booking")}</Link></li>
              <li><Link to="/pages">{t("footer.link_website")}</Link></li>
              <li><button style={{backgroundColor: "transparent", border: "none", padding: "0px"}} onClick={() => utils.openHubspot(window)}> {t("footer.link_contact")}</button></li>
            </ul>
          </div>
        </div>
        {/* footer link 3 */}
        <div className="col-lg-3 col-md-6 col-12">
          <div className="links">
            <h3>{t("footer.help")}</h3>
            <ul>
              <li><Link to="privacy-policies">{t("footer.link_terms")}</Link></li>
              <li><a href="mailto:support@logpot.io" target="_blank">support@logpot.io</a></li>
              <li><a href="https://wa.me/+59891866395?text=Hola Logpot! necesito asistencia" target="_blank">Wpp: +598 91 866 395</a></li>
            </ul>
            
          </div>
        </div>
        {/* footer link 4 */}
        <div className="col-lg-2 col-md-6 col-12">
          <div className="try_out">
            <h3>{t("footer.pocket_management")}</h3>
            <ul className="app_btn">
              <li>
                <a href="https://apps.apple.com/uy/app/logpot-admin/id1664265745" target="_blank">
                  <img src="images/appstore_blue.png" alt="image" />
                </a>
              </li>
              <li>
                <a href="https://play.google.com/store/apps/details?id=io.logpot.clients.backoffice&pli=1" target="_blank">
                  <img src="images/googleplay_blue.png" alt="image" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* row end */}
    </div>
    {/* container end */}
  </div>
  <hr/>
  </FooterContainer>
)
}

const FooterContainer = styled.div`

.logo {
    margin-bottom: 10px;
    display: flex;

    img{
        width: 150px !important;
        margin-top: -4px;
        margin-left: -15px;
    }
}

.top_footer {
    padding: 20px 0 60px 0;
    position: relative;
    overflow: hidden;
}

 h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 50px;
    padding-top: 10px;
}

h2, h3 {
    color: var(--dark-purple);
}

.links ul li a {
    display: block;
    margin-bottom: 10px;
}

a {
    text-decoration: none !important;
    color: var(--body-text-purple) !important;
}

.top_footer {
    margin-top: -30px !important;
}

.social_media {
    display: flex;
    margin-top: 20px;

    a {
    display: block;
    width: 37px;
    height: 37px;
    text-align: center;
    line-height: 37px;
    font-size: 15px;
    border: 1px solid #dddde2;
    border-radius: 50px;
    margin-right: 10px;
    transition: .4s all;
}
}

li{
    text-align: left;
}

.app_btn li a {
    display: block;
    padding: 12px 10px;
    background-color: var(--bg-white);
    border: 2px solid var(--body-text-purple);
    position: relative;
    border-radius: 12px;
    transition: .4s all;
    width: 175px;
    text-align: center;
    margin: 15px 0px;
}

`

export default FooterComponent