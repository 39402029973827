import { store } from "react-notifications-component";
import mixpanel from 'mixpanel-browser';
import { PRICING_PLANS } from "./pricing";
import TagManager from 'react-gtm-module';


class Utils {

  sendGTM() {
    try {
      const eventData = {
        event: 'conversion',
        send_to: 'AW-11092187660/SSJHCIKLmKUYEIy0lakp'
      }
      TagManager.dataLayer({ dataLayer: eventData });
    } catch (error) {
    }
  }

  formatDateTime(date) {
    if (date != null) {
      let changeDate = new Date(date);
      date = new Intl.DateTimeFormat("en-UY", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" }).format(changeDate);
    }
    return date;
  }

  formatCurrency(value) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    if (value !== null && value !== undefined && value !== "" && !isNaN(value)) {
      return formatter.format(value);
    }
    if (isNaN(value)) {
      return formatter.format(0);
    }
    return value;
  }

  sendNotification(title, message, type) {
    store.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: true,
        click: true,
        touch: true,
        showIcon: true
      },
    });
  }


  // Función para obtener el valor de una cookie por su nombre
  getCookie() {
    const name = "_GUESTLPL"
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  }

  // Función para establecer una cookie con el UUID si no existe
  setUUIDCookie(uuid) {
    document.cookie = '_GUESTLPL=' + uuid + '; path=/';
  }

  trackingMetric(metric) {
    mixpanel.track(metric)
  }

  getRandomVariant() {
    const randomNumber = Math.random();
    if (randomNumber < 0.5) {
      return 'USD';
    }
    else {
      return 'UYU';
    }
  }

  getUserVariant() {
    const user = this.getCookie()
    if (user != null) {
      const result = user.split(".")
      if (result.length > 1) {
        console.log("user variant")
        console.log(result[0])
        return result[0]
      } else {
        return this.getRandomVariant()
      }
    }
  }

  getPricing(country) {
    if (PRICING_PLANS[country] === undefined) {
      country = "UNKNOWN"
    }
    return PRICING_PLANS[country]
  }

  openHubspot(window) {
    /* eslint-disable-next-line */
    if (window.LO) {
      window.LO.messenger.open()
    }
  }
}

const utils = new Utils();

export default utils;
