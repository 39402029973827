import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import FooterComponent from "../components/footerComponent";
import { Link } from "react-router-dom";
import utils from "../utils/utils";

const MenuView = (props) => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    utils.trackingMetric("MENU_VIEW")
    scrollToTop()
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };


  return (
    <React.Fragment>
      < section id="home" name="home-nav" style={{ backgroundColor: "#fff6f6", padding: "100px 0px", marginTop: "10px" }}>
        {/* container start */}
        < div className="container" >
          {/* vertical animation line */}
          {/* row start */}
          < div className="row" >
            <div className="col-lg-6 col-md-12" data-aos="fade-right" data-aos-duration={1500}>
              {/* banner text */}
              <div className="banner_text">
                {/* h1 */}
                <span><Link to="/">{t("_HOME")}</Link> » {t('menu.title')}</span>
                <h1 style={{ marginTop: "30px" }}>
                  {t('menu.heading')}
                </h1>
                {/* p */}
                <p>{t('menu.description')}</p>
                <a className="button-cover" style={{ marginRight: "15px" }} href="https://miembros.logpot.io/creacion-de-organizacion-datos-iniciales"  target="_blank" rel="noopener noreferrer">{t('menu.button1')}</a>
              </div>
              {/* users */}
            </div>
            {/* banner slides start */}
            <div className="col-lg-6 col-md-12" data-aos="fade-in" data-aos-duration={5000} >

              <div class="slider_frame">
                <img src={require("../assets/frame2.png")} alt="image" style={{ maxWidth: "650px" }} className="product-image" />
              </div>
            </div>
            {/* banner slides end */}
          </div >
          {/* row end */}
        </div >
        {/* container end */}
      </section >

      <section className="row_am features_section" id="product" name="home-product" style={{ marginTop: "40px" }}>
        {/* container start */}
        <div className="container">
          <div className="section_title" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={100}>
            {/* h2 */}
            <h2><Trans i18nKey="product.title" components={{ span: <span /> }} /></h2>
            <p><Trans i18nKey="product.description" components={{ br: <br /> }} /></p>
          </div>
          <div className="feature_detail">
            {/* feature box left */}
            <div className="left_data feature_box">
              {/* feature box */}
              <div className="data_block" data-aos="fade-right" data-aos-duration={1500}>
                <div className="icon">
                  <img src={require("../assets/icons/save_money.svg")} alt="image" className="icon-business" />
                </div>
                <div className="text">
                  <h4>{t("product.save_money_title")}</h4>
                  <p>{t("product.save_money_description")}</p>
                </div>
              </div>
              {/* feature box */}
              <div className="data_block" data-aos="fade-right" data-aos-duration={1500}>
                <div className="icon">
                  <img src={require("../assets/icons/register.svg")} alt="image" className="icon-business" />
                </div>
                <div className="text">
                  <h4>{t("product.sell_title")}</h4>
                  <p>{t("product.sell_description")}</p>
                </div>
              </div>
            </div>
            {/* feature box right */}
            <div className="right_data feature_box">
              {/* feature box */}
              <div className="data_block" data-aos="fade-left" data-aos-duration={1500}>
                <div className="icon">
                  <img src={require("../assets/icons/menu.svg")} alt="image" className="icon-business" />
                </div>
                <div className="text">
                  <h4>{t("product.menu_title")}</h4>
                  <p>{t("product.menu_description")}</p>
                </div>
              </div>
              {/* feature box */}
              <div className="data_block" data-aos="fade-left" data-aos-duration={1500}>
                <div className="icon">
                  <img src={require("../assets/icons/stats.svg")} alt="image" className="icon-business" />
                </div>
                <div className="text">
                  <h4>{t("product.stats_title")}</h4>
                  <p>{t("product.stats_description")}</p>
                </div>
              </div>
            </div>
            {/* feature image */}
            <div className="feature_img" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={100}>
              <img src={require("../assets/products/menu_template.png")} alt="image" style={{ width: "320px" }} />
            </div>
          </div>
        </div>
        {/* container end */}
      </section>
      <section className="row_am testimonial_section">
        {/* container start */}
        <div className="container">

          <div className="section_title" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={300}>
            <h2><Trans i18nKey="rating.title" components={{ span: <span /> }} /></h2>
            <p><Trans i18nKey="rating.description" components={{ br: <br /> }} /></p>
          </div>
          <div className="testimonial_block" data-aos="fade-in" data-aos-duration={1500}>
            <div id="testimonial_slider" className="owl-carousel owl-theme">
            </div>
            {/* total review */}
            <div className="total_review">
              <div className="rating">
                <span><i className="icofont-star" /></span>
                <span><i className="icofont-star" /></span>
                <span><i className="icofont-star" /></span>
                <span><i className="icofont-star" /></span>
                <span><i className="icofont-star" /></span>
                <p>4.6 / 5.0</p>
              </div>
              <h3>1078</h3>
              <a>{t("rating.total_ratings")}</a>
            </div>
          </div>
          <hr style={{ marginTop: "100px" }} />

        </div>
        {/* container end */}
      </section>
      <section className="row_am about_app_section" id="sustentability" name="home-sustentability">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row">
            <div className="col-lg-6">
              {/* about images */}
              <div className="about_img" data-aos="fade-in" data-aos-duration={1500}>
                <div className="frame_img">
                  <img className="moving_position_animatin" src={require("../assets/template/menu_template_qr.svg")} style={{ width: "300px" }} alt="image" />
                </div>
                <div className="screen_img">
                  <img className="moving_animation" src={require("../assets/template/sustentability_1.svg")} alt="image" style={{ width: "300px" }} />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              {/* about text */}
              <div className="about_text">
                <div className="section_title" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={100}>
                  <h2><Trans i18nKey="sustainability.title" components={{ span: <span /> }} /></h2>
                  <p><Trans i18nKey="sustainability.description" components={{ br: <br /> }} /></p>
                </div>
                {/* UL */}
                <ul className="app_statstic" id="counter" data-aos="fade-in" data-aos-duration={1500}>
                  <li>
                    <div className="icon">
                      <img src={require("../assets/icons/water.png")} alt="image" />
                    </div>
                    <div className="text">
                      <p><span className="counter-value" data-count={32}>+32</span><span>k</span></p>
                      <p>{t("sustainability.water")}</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src={require("../assets/icons/co2.png")} alt="image" />
                    </div>
                    <div className="text">
                      <p><span className="counter-value" data-count={240}>+240 </span><span> KG</span></p>
                      <p>{t("sustainability.co2")}</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src={require("../assets/icons/tree.png")} alt="image" />
                    </div>
                    <div className="text">
                      <p><span className="counter-value" data-count={1}>+1</span><span></span></p>
                      <p>{t("sustainability.trees")}</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src={require("../assets/icons/oxygen.png")} alt="image" />
                    </div>
                    <div className="text">
                      <p><span className="counter-value" data-count={5}>5</span><span> Pers.</span></p>
                      <p>{t("sustainability.o2")}</p>
                    </div>
                  </li>
                </ul>
                {/* UL end */}
                <a href="https://miembros.logpot.io/creacion-de-organizacion-datos-iniciales"  target="_blank" rel="noopener noreferrer" className="btn puprple_btn" data-aos="fade-in" data-aos-duration={1500}>{t("sustainability.button")}</a>
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/* About-App-Section-end */}
      {/* ModernUI-Section-Start */}
      <section className="row_am modern_ui_section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row">
            <div className="col-lg-6">
              {/* UI content */}
              <div className="ui_text">
                <div className="section_title" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={100}>
                  <h2><Trans i18nKey="benefits.title" components={{ span: <span /> }} /></h2>
                  <p><Trans i18nKey="benefits.description" components={{ br: <br /> }} /></p>
                </div>
                <ul className="design_block">
                  <li data-aos="fade-up" data-aos-duration={1500}>
                    <h4>{t("benefits.easy_title")}</h4>
                    <p>{t("benefits.easy_description")}</p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration={1500}>
                    <h4>{t("benefits.order_detail_title")}</h4>
                    <p>{t("benefits.order_detail_description")}</p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration={1500}>
                    <h4>{t("benefits.split_order_title")}</h4>
                    <p>{t("benefits.split_order_description")}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              {/* UI Image */}
              <div className="ui_images" data-aos="fade-in" data-aos-duration={1500}>
                <div className="left_img">
                  <img className="moving_position_animatin" src={require("../assets/icons/restaurant.svg")} alt="image" style={{ height: "550px" }} />
                </div>
                {/* UI Image */}
                <div className="right_img">
                  <img className="moving_position_animatin" src={require("../assets/icons/order-food.svg")} alt="image" style={{ height: "256px" }} />
                  <img className="moving_position_animatin" src={require("../assets/icons/price.svg")} alt="image" style={{ height: "117px" }} />
                </div>
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/* ModernUI-Section-end */}
      {/* How-It-Workes-Section-Start */}
      <section className="row_am how_it_works" id="how_it_work">
        {/* container start */}
        <div className="container">
          <div className="how_it_inner">
            <div className="section_title" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={300}>
              {/* h2 */}
              <h2><Trans i18nKey="use_steps.title" components={{ span: <span /> }} /></h2>
              <p><Trans i18nKey="use_steps.description" components={{ br: <br /> }} /></p>
            </div>
            <div className="step_block">
              {/* UL */}
              <ul>
                {/* step */}
                <li>
                  <div className="step_text" data-aos="fade-right" data-aos-duration={1500}>
                    <h4>{t("use_steps.step_1_title")} </h4>
                    <p>{t("use_steps.step_1_description")} </p>
                  </div>
                  <div className="step_number">
                    <h3>01</h3>
                  </div>
                  <div className="step_img" data-aos="fade-left" data-aos-duration={1500}>
                    <img src={require("../assets/icons/step-1.svg")} alt="image" style={{ height: "240px", borderRadius: "15px" }} />
                  </div>
                </li>
                {/* step */}
                <li>
                  <div className="step_text" data-aos="fade-left" data-aos-duration={1500}>
                    <h4>{t("use_steps.step_2_title")} </h4>
                    <p>{t("use_steps.step_2_description")} </p>
                  </div>
                  <div className="step_number">
                    <h3>02</h3>
                  </div>
                  <div className="step_img" data-aos="fade-right" data-aos-duration={1500}>
                    <img src={require("../assets/icons/step_2.svg")} alt="image" style={{ height: "240px", borderRadius: "15px" }} />
                  </div>
                </li>
                {/* step */}
                <li>
                  <div className="step_text" data-aos="fade-right" data-aos-duration={1500}>
                    <h4>{t("use_steps.step_3_title")} </h4>
                    <p>{t("use_steps.step_3_description")} </p>
                  </div>
                  <div className="step_number">
                    <h3>03</h3>
                  </div>
                  <div className="step_img" data-aos="fade-left" data-aos-duration={1500}>
                    <img src={require("../assets/icons/step_3.svg")} alt="image" style={{ height: "240px", borderRadius: "15px" }} />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* video section start */}
          {/* video section end */}
          <hr style={{ marginTop: "60px" }} /> </div>
        {/* container end */}
      </section>
      <FooterComponent />
    </React.Fragment>
  );
};

export default MenuView;
