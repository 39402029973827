import React from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import styled, { keyframes } from "styled-components";

const LoadingComponent = (props) => {
  //Properties (title,description)
  return (
    <LoadingBoxContainer style={{backgroundColor: props.transtion == true ? "#f6f6f6" :"b6b3b35e" }}>
      <LoadingImage src={require("../assets/bussiness/isologo.svg")} />
    </LoadingBoxContainer>
  );
};

export default LoadingComponent;

// Create a Wrapper component that'll render a <section> tag with some styles
const LoadingBoxContainer = styled.div`
  position: fixed;
  background-color: #f6f4fe;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
  top: 0;
  left: 0;
`;

const spinnerImage = keyframes`
  0% {
    animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingImage = styled.img`
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100px;
  animation: 1.4s linear 0s infinite normal forwards running ${spinnerImage};
`;


