import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import FooterComponent from "../components/footerComponent";
import { Link } from "react-router-dom";
import utils from "../utils/utils";

const PagesView = (props) => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    utils.trackingMetric("PAGES_VIEW")
    scrollToTop()
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <React.Fragment>
      <section id="home" name="home-nav" style={{ backgroundColor: "#fff6f6", padding: "100px 0px", marginTop: "10px" }}>
        {/* container start */}
        <div className="container">
          {/* vertical animation line */}
          {/* row start */}
          <div className="row">
            <div className="col-lg-6 col-md-12" data-aos="fade-right" data-aos-duration={1500}>
              {/* banner text */}
              <div className="banner_text">
                {/* h1 */}
                <span><Link to="/">{t("_HOME")}</Link> » {t('pages.title')}</span>
                <h1 style={{ marginTop: "30px" }}>
                  {t('pages.heading')}
                </h1>
                {/* p */}
                <p>{t('pages.description')}</p>
                <a className="button-cover" style={{ marginRight: "15px" }} href="https://miembros.logpot.io/creacion-de-organizacion-datos-iniciales"  target="_blank" rel="noopener noreferrer">{t('navbar.button')}</a>
              </div>
              {/* users */}
            </div>
            {/* banner slides start */}
            <div className="col-lg-6 col-md-12" data-aos="fade-in" data-aos-duration={5000}>

              <div class="slider_frame">
                <img src={require("../assets/products/pages.png")} alt="image" style={{ maxWidth: "650px" }} className="product-image" />
              </div>
            </div>
            {/* banner slides end */}
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      <section className="row_am features_section" id="features" style={{ marginTop: "40px" }}>
        {/* section bg */}
        <div className="feature_section_bg"></div>
        {/* container start */}
        <div className="container">
          <div className="features_inner" style={{ paddingBottom: "40px" }}>
            {/* feature image */}

            <div className="section_title aos-init aos-animate" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={300}>
              {/* h2 */}
              <h2>{t('pages.generatorTitle')}</h2>
              {/* p */}
              <p>{t('pages.generatorDescription')}</p>
            </div>
            {/* story */}
            <div className="features_block" style={{ marginTop: "50px" }}>
              <div className="row">
                <div className="col-md-3">
                  <div className="feature_box aos-init aos-animate" data-aos="fade-up" data-aos-duration={1500}>

                    <div className="text">
                      <h4>{t('pages.domainTitle')}</h4>
                      <p>{t('pages.domainDescription')}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="feature_box aos-init aos-animate" data-aos="fade-up" data-aos-duration={1700}>

                    <div className="text">
                      <h4>{t('pages.socialTitle')}</h4>
                      <p>{t('pages.socialDescription')}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="feature_box aos-init aos-animate" data-aos="fade-up" data-aos-duration={1900}>

                    <div className="text">
                      <h4>{t('pages.linksTitle')}</h4>
                      <p>{t('pages.linksDescription')}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="feature_box aos-init aos-animate" data-aos="fade-up" data-aos-duration={1900}>

                    <div className="text">
                      <h4>{t('pages.statsTitle')}</h4>
                      <p>{t('pages.statsDescription')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* container end */}
      </section>
      <section className="row_am about_app_section modern_ui_section" id="sustentability" name="home-sustentability">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row">
            <div className="col-lg-6">
              {/* about text */}
              <div className="about_text">
                <div className="section_title" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={100}>
                  <h2>{t('pages.presenceTitle')}</h2>
                  <p>{t('pages.presenceDescription')}</p>
                </div>
                {/* UL */}
                <ul class="design_block">
                  <li data-aos="fade-up" data-aos-duration="1500" class="aos-init aos-animate">
                    <h4>{t('pages.seoTitle')}</h4>
                    <p>{t('pages.seoDescription')}</p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500" class="aos-init aos-animate">
                    <h4>{t('pages.centralizeTitle')}</h4>
                    <p>{t('pages.centralizeDescription')}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              {/* about images */}
              <div className="about_img" data-aos="fade-in" data-aos-duration={1500} style={{
                display: "flex",
                justifyContent: "center"
              }}>
                <div className="frame_img" >
                  <img className="moving_position_animatin" src={require("../assets/products/page.png")} style={{ width: "300px" }} alt="image" />
                </div>
              </div>
            </div>
          </div>
          {/* row end */}
          <hr style={{ marginTop: "70px" }} />
        </div>
        {/* container end */}
      </section>
      <FooterComponent />
    </React.Fragment>
  );
};

export default PagesView;
