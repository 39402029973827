import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import FooterComponent from "../components/footerComponent";
import { Link } from "react-router-dom";
import utils from "../utils/utils";
const BookingView = (props) => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    utils.trackingMetric("BOOKING_VIEW")
    scrollToTop()
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };


  return (
    <React.Fragment>
    <section id="home" name="home-nav" style={{ backgroundColor: "#fff6f6", padding: "100px 0px", marginTop: "10px" }}>
      {/* container start */}
      <div className="container">
        {/* vertical animation line */}
        {/* row start */}
        <div className="row">
          <div className="col-lg-6 col-md-12" data-aos="fade-right" data-aos-duration={1500}>
            {/* banner text */}
            <div className="banner_text">
              {/* h1 */}
              <span><Link to="/">{t("_HOME")}</Link> » {t('booking.title')}</span>
              <h1 style={{ marginTop: "30px" }}>
                {t('booking.heading')}
              </h1>
              {/* p */}
              <p>{t('booking.description')}</p>
              <a className="button-cover" style={{ marginRight: "15px" }} href="https://miembros.logpot.io/creacion-de-organizacion-datos-iniciales"  target="_blank" rel="noopener noreferrer" >{t('booking.button1')}</a>
            </div>
            {/* users */}
          </div>
          {/* banner slides start */}
          <div className="col-lg-6 col-md-12" data-aos="fade-in" data-aos-duration={5000} >
            <div class="slider_frame">
              <img src={require("../assets/products/booking.png")} alt="image" style={{ maxWidth: "650px" }} className="product-image"/>
            </div>
          </div>
          {/* banner slides end */}
        </div>
        {/* row end */}
      </div>
      {/* container end */}
    </section>
    <section className="row_am features_section" id="features" style={{marginTop: "40px"}}>
      {/* section bg */}
      <div className="feature_section_bg"></div>
      {/* container start */}
      <div className="container">
        <div>
          {/* feature image */}
          <div className="section_title aos-init aos-animate" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={300}>
            {/* h2 */}
            <h2><span>{t('booking.centralize_title')}</span></h2>
            {/* p */}
            <p>{t('booking.centralize_description')}</p>
          </div>
          {/* story */}
          <div className="features_block">
            <div style={{ display: "flex", justifyContent: "center", margin: "30px 0px" }}>
              <img src={require("../assets/products/bookingTable.png")} style={{ maxWidth: "700px" }} className="product-image"/>
            </div>
            <div className="row" style={{marginTop: "60px"}}>
              <div className="col-md-3">
                <div className="feature_box aos-init aos-animate" data-aos="fade-up" data-aos-duration={1500}>
                  <div className="text">
                    <h4>{t('booking.table_listing_title')}</h4>
                    <p>{t('booking.table_listing_description')}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="feature_box aos-init aos-animate" data-aos="fade-up" data-aos-duration={1700}>
                  <div className="text">
                    <h4>{t('booking.available_hours_title')}</h4>
                    <p>{t('booking.available_hours_description')}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="feature_box aos-init aos-animate" data-aos="fade-up" data-aos-duration={1900}>
                  <div className="text">
                    <h4>{t('booking.calendar_title')}</h4>
                    <p>{t('booking.calendar_description')}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="feature_box aos-init aos-animate" data-aos="fade-up" data-aos-duration={1900}>
                  <div className="text">
                    <h4>{t('booking.statistics_title')}</h4>
                    <p>{t('booking.statistics_description')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* container end */}
    </section>
    <section className="row_am about_app_section modern_ui_section" id="sustentability" name="home-sustentability">
      {/* container start */}
      <div className="container">
        {/* row start */}
        <div className="row">
          <div className="col-lg-6">
            {/* about images */}
            <div className="about_img" data-aos="fade-in" data-aos-duration={1500} style={{
                display: "flex",
                justifyContent: "center"
              }}>
              <div className="frame_img" >
                <img className="moving_position_animatin product-image" src={require("../assets/products/wpp.png")} style={{ width: "300px" }} alt="image" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            {/* about text */}
            <div className="about_text">
              <div className="section_title" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={100}>
                <h2>{t('booking.client_experience_title')}</h2>
                <p>{t('booking.client_experience_description')}</p>
              </div>
              {/* UL */}
              <ul class="design_block">
                <li data-aos="fade-up" data-aos-duration="1500" class="aos-init aos-animate">
                  <h4>{t('booking.satisfaction_survey_title')}</h4>
                  <p>{t('booking.satisfaction_survey_description')}</p>
                </li>
                <li data-aos="fade-up" data-aos-duration="1500" class="aos-init aos-animate">
                  <h4>{t('booking.whatsapp_communication_title')}</h4>
                  <p>{t('booking.whatsapp_communication_description')}</p>
                </li>
                <li data-aos="fade-up" data-aos-duration="1500" class="aos-init aos-animate">
                  <h4>{t('booking.contact_list_title')}</h4>
                  <p>{t('booking.contact_list_description')}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* row end */}
        <hr style={{marginTop: "70px"}}/>
      </div>
      {/* container end */}
    </section>
    <FooterComponent />
  </React.Fragment>
  );
};

export default BookingView;
