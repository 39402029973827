import React, { useEffect, useState, useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";


const FaqComponent = () => {
    const { t, i18n } = useTranslation();




    return (
        <section className="row_am" id="faq">
            <div className="container">
                <div className="section_title aos-init aos-animate" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={300}>
                    {/* h2 */}
                    <h2><span>{t('faq.title')}</span> - {t('faq.subtitle')}</h2>
                    {/* p */}
                    <p>{t('faq.description')}</p>
                </div>
                {/* faq data */}
                <div className="faq_panel">
                    <div className="accordion" id="accordionExample">
                        <div className="card aos-init aos-animate" data-aos="fade-up" data-aos-duration={1500}>
                            <div className="card-header" id="headingOne">
                                <h2 className="mb-0">
                                    <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false">
                                        <i className="icon_faq icofont-plus" /> {t('faq.question1')}
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample" style={{}}>
                                <div className="card-body">
                                    <p>{t('faq.answer1')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="card aos-init aos-animate" data-aos="fade-up" data-aos-duration={1500}>
                            <div className="card-header" id="headingTwo">
                                <h2 className="mb-0">
                                    <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false">
                                        <i className="icon_faq icofont-plus" /> {t('faq.question2')}
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample" style={{}}>
                                <div className="card-body">
                                    <p>{t('faq.answer2')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="card aos-init aos-animate" data-aos="fade-up" data-aos-duration={1500}>
                            <div className="card-header" id="headingThree">
                                <h2 className="mb-0">
                                    <button type="button" className="btn btn-link active" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true">
                                        <i className="icon_faq icofont-minus" /> {t('faq.question3')}
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseThree" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample" style={{}}>
                                <div className="card-body">
                                    <p>{t('faq.answer3')}</p>
                                    <strong>Whatsapp: <span>{t('faq.whatsapp_number')}</span></strong> <br />
                                    <strong>Email: <span>{t('faq.email')}</span></strong>
                                </div>
                            </div>
                        </div>
                        <div className="card aos-init" data-aos="fade-up" data-aos-duration={1500}>
                            <div className="card-header" id="headingFour">
                                <h2 className="mb-0">
                                    <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour">
                                        <i className="icon_faq icofont-plus" /> {t('faq.question4')}
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div className="card-body">
                                    <p>{t('faq.answer4')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style={{ marginTop: "100px" }} />
            </div>
        </section>
    )
}

export default FaqComponent