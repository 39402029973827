import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import Flag from 'react-world-flags'
import { scroller } from "react-scroll";
import { Link } from "react-router-dom";
import utils from "../utils/utils";

const NavbarComponent = (props) => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmenu, setIsSubMenu] = useState(false);
  const [isProductsActive, setIsProductsActive] = useState(true)

  const changeLanguage = (lng) => {
    setIsLoading(true);
    i18n.changeLanguage(lng);
    setTimeout(() => {
      setIsLoading(false);
      setIsSubMenu(false);
    }, 500);
  };


  //Properties (title,description)
  return (
    < header>
      {/* container start */}
      < div className="container" >
        {/* navigation bar */}
        < nav className="navbar navbar-expand-lg" >
          <a className="navbar-brand" href="/#home">
            <img src={require("../assets/bussiness/logo-orange.svg")} alt="image" />
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon">
              <div className="toggle-wrap">
                <span className="toggle-bar" />
              </div>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">

              <li className="nav-item">
                <Link className="nav-link" to="/">{t("navbar.home")}</Link>
              </li>
              <li className="nav-item has_dropdown  desktop-sub-menu" onClick={() => setIsProductsActive(!isProductsActive)}>
                <button className="nav-link" style={{ backgroundColor: "transparent", border: "none" }}>{t("navbar.product")}</button>
                <span className="drp_btn"><i className="icofont-rounded-down"></i></span>
                <div className="sub_menu">
                  <ul>
                    <li><a href="/booking">{t("navbar.booking")}</a></li>
                    <li><Link to="/menu">{t("navbar.menu")}</Link></li>
                    <li><a href="/pages">{t("navbar.pages")}</a></li>
                  </ul>
                </div>
              </li>
              <li className="nav-item has_dropdown mobile-sub-menu" onClick={() => setIsSubMenu(!isSubmenu)}>
                <button className="nav-link" style={{ backgroundColor: "transparent", border: "none" }}>{t("navbar.product")}</button>
                <span className="drp_btn"><i className="icofont-rounded-down"></i></span>
                <div className="sub_menu" style={{ display: isSubmenu === true ? "block" : "none" }}>
                  <ul style={{ marginLeft: "10px" }}>
                    <li><a href="/booking">{t("navbar.booking")}</a></li>
                    <li><Link to="/menu">{t("navbar.menu")}</Link></li>
                    <li><a href="/pages">{t("navbar.pages")}</a></li>
                  </ul>
                </div>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" onClick={() => scrollTo("home-clients", -200)}>{t("navbar.clients")}</a>
              </li> */}
              <li className="nav-item">
                <button className="nav-link" onClick={() => utils.openHubspot(window)} style={{ backgroundColor: "transparent", border: "none" }}>{t("navbar.contact")}</button>
              </li>
              <li className="nav-item has_dropdown desktop-sub-menu">
                <button style={{ backgroundColor: "transparent", border: "none" }} className="nav-link">{i18n.language === "en" ? <Flag code="US" className="icon-flag" alt="us" /> : i18n.language === "pt" ? <Flag code="BR" className="icon-flag" alt="br" /> : <Flag code="ES" className="icon-flag" alt="spain" />}  {i18n.language === "en" ? "English" : i18n.language === "pt" ? "Portugues" : "Español"}</button>
                <span className="drp_btn"><i className="icofont-rounded-down"></i></span>
                <div className="sub_menu">
                  <ul>
                    {i18n.language !== "es" &&
                      <li><button style={{ backgroundColor: "transparent", border: "none" }} onClick={() => changeLanguage("es")}><Flag code="ES" className="icon-flag" alt="spain" /> Spanish</button></li>
                    }  {i18n.language !== "en" &&
                      <li><button style={{ backgroundColor: "transparent", border: "none" }} onClick={() => changeLanguage("en")}><Flag code="US" className="icon-flag" alt="us" /> English</button></li>
                    }
                    {i18n.language !== "pt" &&
                      <li><button style={{ backgroundColor: "transparent", border: "none" }} onClick={() => changeLanguage("pt")}><Flag code="BR" className="icon-flag" alt="br" /> Portugues</button></li>
                    }

                  </ul>
                </div>
              </li>
              <li className="nav-item has_dropdown mobile-sub-menu" onClick={() => setIsSubMenu(!isSubmenu)}>
                <button className="nav-link">{i18n.language === "en" ? <Flag code="US" className="icon-flag" alt="us" /> : i18n.language === "pt" ? <Flag code="BR" className="icon-flag" alt="br" /> : <Flag code="ES" className="icon-flag" alt="spain" /> }  {i18n.language === "en" ? "English" : i18n.language === "pt" ? "Portugues" :  "Español"}</button>
                <span className="drp_btn"><i className="icofont-rounded-down"></i></span>
                <div className="sub_menu" style={{ display: isSubmenu === true ? "block" : "none" }}>
                  <ul>
                    {i18n.language !== "es" &&
                      <li><button style={{ backgroundColor: "transparent", border: "none" }} onClick={() => changeLanguage("es")}><Flag code="ES" className="icon-flag" alt="spain" /> Spanish</button></li>
                    }  {i18n.language !== "en" &&
                      <li><button style={{ backgroundColor: "transparent", border: "none" }} onClick={() => changeLanguage("en")}><Flag code="US" className="icon-flag" alt="us" /> English</button></li>
                    }
                    {i18n.language !== "pt" &&
                      <li><button style={{ backgroundColor: "transparent", border: "none" }} onClick={() => changeLanguage("pt")}><Flag code="BR" className="icon-flag" alt="br" /> Portugues</button></li>
                    }
                  </ul>
                </div>
              </li>
              {/* secondery menu start */}
              <li className="nav-item">
                <a className="nav-link dark_btn pointer" href="https://miembros.logpot.io/creacion-de-organizacion-datos-iniciales" target="_blank" rel="noopener noreferrer" style={{ border: "none" }}>{t("navbar.button")}</a>
              </li>
            </ul>
          </div>
        </nav >
        {/* navigation end */}
      </div >
      {/* container end */}
    </header >
  );
};

export default NavbarComponent;


